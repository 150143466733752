import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "DateDetails",
  code: "DateDetails"
};
export const _frontmatter = {
  "menuLabel": "Date Details",
  "sortOrder": 4.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Date Details`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-datedetails--divider-default" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { DateDetails } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p><inlineCode parentName="p">{`DateDetails`}</inlineCode>{` provides an easy way to show day and time from any datestring or date object.   `}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
    The Date Details component has a clear visual display that distinguishes the day of the week and start time from other content on the page. It utilizes appropriate color contrast and font size to ensure readability for all users.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    While the Date Details component may not have direct user interactivity, it is keyboard navigable, allowing users to access the information easily using keyboard navigation. It also provides focus indicators for keyboard users.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Date Details component is clear through descriptive labels or headings. Users can easily understand the displayed day of the week and start time for the match.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Date Details component is designed to be compatible with assistive technologies, ensuring that screen readers can access the information accurately. It functions consistently across various browsers and platforms.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      